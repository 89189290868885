import * as React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import MainLayout from 'components/layouts/main';
import ShareResourceDialog, {
  BaseShareResourceProps
} from 'components/dialogs/shareResource';
import ListView from 'components/ui/listView';
import {
  deleteReport,
  getReports,
  updateReport,
  createReport,
  duplicateReport
} from 'libs/api';
import { projectTheme } from '../../theme';

const ReportListPage = () => {
  const { id } = useParams<{ id?: string }>();

  const [state, setState] = React.useState<BaseShareResourceProps>({
    type: 'report',
    id: '',
    open: false,
    editable: false,
    title: ''
  });

  const onClose = () => {
    setState({ ...state, open: false });
  };

  const onDialogOpen = (
    reportId: string,
    shareEditable: boolean,
    title: string,
    isFolder: boolean
  ) => {
    setState({
      type: isFolder ? 'folder' : 'report',
      id: reportId,
      editable: shareEditable,
      open: true,
      title
    });
  };

  return (
    <MainLayout selectedMenu="reports">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="reports"
            folderId={id}
            load={getReports}
            add={createReport}
            duplicate={duplicateReport}
            move={(uuid: string) => {
              window.onbeforeunload = null;
              window.location.href = `/reports/${uuid}`;
              return;
            }}
            delete={deleteReport}
            update={updateReport}
            enableShare={true}
            onOpenShareDialog={onDialogOpen}
            showDetailPane={true}
            supportFolder={true}
            nameBeforeAdding={true}
          />
        </ThemeProvider>
        <ShareResourceDialog {...state} onClose={onClose} />
      </Grid>
    </MainLayout>
  );
};

export default ReportListPage;
