import * as React from 'react';
import MainLayout from 'components/layouts/main';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ShareResourceDialog, {
  BaseShareResourceProps
} from 'components/dialogs/shareResource';
import ListView from 'components/ui/listView';
import {
  deleteConnections,
  getConnections,
  updateConnectionName
} from 'libs/api';
import appHistory from '../../appHistory';
import { projectTheme } from '../../theme';
import { QueryParams } from 'ui/listViewBase';

const ConnectionListPage = () => {
  const [state, setState] = React.useState<BaseShareResourceProps>({
    id: '',
    open: false,
    editable: false,
    title: '',
    type: 'connection'
  });

  const loadFunc = React.useMemo(() => {
    return async (_, queryParams?: QueryParams) => {
      const { data } = await getConnections('all', queryParams);
      return { data: { items: data, paths: [] } };
    };
  }, []);

  const onClose = () => {
    setState({ ...state, open: false });
  };

  const onDialogOpen = (
    connectionId: string,
    shareEditable: boolean,
    projectName: string
  ) => {
    setState({
      id: connectionId,
      editable: shareEditable,
      open: true,
      title: projectName,
      type: 'connection'
    });
  };

  const update = async (id: string, data: any) => {
    await updateConnectionName(id, data.name);
  };

  return (
    <MainLayout selectedMenu="connections">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="connections"
            load={loadFunc}
            move={async () => appHistory.push('/connections/new')}
            delete={deleteConnections}
            update={update}
            onOpenShareDialog={onDialogOpen}
            isOpenShareDialog={state.open}
            showDetailPane={true}
            supportFolder={false}
          />
        </ThemeProvider>
        <ShareResourceDialog {...state} onClose={onClose} />
      </Grid>
    </MainLayout>
  );
};

export default ConnectionListPage;
