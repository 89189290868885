import * as React from 'react';
import MainLayout from 'components/layouts/main';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ListView from 'components/ui/listView';
import {
  addUserGroup,
  deleteUserGroup,
  getAdminUserGroups,
  putUserGroup
} from 'libs/api';
import { projectTheme } from '../../../theme';
import { QueryParams } from 'ui/listViewBase';
import { UserGroupItem } from 'models/dependency';
import { Dialog } from 'ui/common/dialog';
import { UserGroup } from 'models/user_group';
import { useDisclosure } from '../../../hooks/useDisclosure';
import { TextField } from 'ui/common/textfield';
import * as z from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const AdminUserGroupListPage = () => {
  const loadFunc = React.useMemo(() => {
    return async (_, queryParams?: QueryParams) => {
      const { data } = await getAdminUserGroups(queryParams);
      return { data: { items: data, paths: [] } };
    };
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGroup, setSelectedGroup] = React.useState<UserGroup | null>(
    null
  );
  const [key, setKey] = React.useState(0);

  return (
    <MainLayout selectedMenu="admin/user_groups">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            key={key}
            type="user_groups"
            load={loadFunc}
            move={() => {
              setSelectedGroup(null);
              onOpen();
            }}
            onClickItemLink={(item: UserGroupItem) => {
              setSelectedGroup({
                id: item.uuid,
                name: item.name,
                ip_address: item.ip_address,
                all_users: item.all_users
              });
              onOpen();
            }}
            delete={deleteUserGroup}
            showDetailPane={false}
            supportFolder={false}
            isAdminPage={true}
          />
        </ThemeProvider>
      </Grid>
      <EditUserGroupDialog
        key={selectedGroup ? selectedGroup.id : 'new'}
        open={isOpen}
        onClose={(reload: boolean) => {
          onClose();
          if (reload) {
            setKey((key) => key + 1);
          }
        }}
        defaultGroup={selectedGroup}
      />
    </MainLayout>
  );
};

const schema = z.object({
  name: z
    .string()
    .min(1, '入力してください')
    .regex(/^[^,]+$/, 'カンマ以外の文字で入力してください'),
  ip_address: z.string()
});

type FieldValues = z.infer<typeof schema>;

const EditUserGroupDialog = ({
  defaultGroup,
  open,
  onClose
}: {
  defaultGroup: UserGroup | null;
  open: boolean;
  onClose: (reload: boolean) => void;
}) => {
  const [processing, setProcessing] = React.useState<boolean>(false);
  const {
    control,
    formState: { isValid, errors },
    reset,
    getValues
  } = useForm<FieldValues>({
    resolver: zodResolver(schema),
    mode: 'all'
  });

  React.useEffect(() => {
    defaultGroup
      ? reset(defaultGroup)
      : reset({
          name: '',
          ip_address: ''
        });
  }, [reset, defaultGroup, open]);

  const save = React.useCallback(async () => {
    try {
      setProcessing(true);
      if (defaultGroup?.id) {
        await putUserGroup({ ...defaultGroup, ...getValues() });
      } else {
        await addUserGroup({ ...getValues(), id: '', all_users: false });
      }
      onClose(true);
    } catch (e) {
      window.alert('エラーが発生しました。');
    } finally {
      setProcessing(false);
    }
  }, [setProcessing, defaultGroup]);

  return (
    <Dialog
      title={defaultGroup?.id ? 'グループの編集' : 'グループの追加'}
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={processing ? () => {} : () => onClose(false)}
      contentProps={{
        style: { display: 'flex', flexDirection: 'column', gap: 16 }
      }}
      OKButton={{
        onClick: save,
        disabled: !isValid || processing
      }}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            label="グループ名"
            required={true}
            InputLabelProps={{ shrink: true }}
            disabled={defaultGroup?.all_users}
            {...field}
            helperText={errors['name']?.message}
            error={!!errors['name']}
          />
        )}
      />
      <Controller
        name="ip_address"
        control={control}
        render={({ field }) => (
          <TextField
            label="ダッシュボード公開制限用IPアドレスを紐付ける"
            InputLabelProps={{ shrink: true }}
            {...field}
          />
        )}
      />
    </Dialog>
  );
};

export default AdminUserGroupListPage;
