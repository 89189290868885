import * as React from 'react';

import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import MainLayout from 'components/layouts/main';
import ShareResourceDialog, {
  BaseShareResourceProps
} from 'components/dialogs/shareResource';
import ListView from 'components/ui/listView';
import {
  addProject,
  deleteProject,
  duplicateProject,
  getProjects,
  shutdownProject,
  updateProjectName
} from 'libs/api';
import { useParams } from 'react-router-dom';
import { projectTheme } from '../../theme';

const ProjectListPage = () => {
  const { id } = useParams<{ id?: string }>();
  const [state, setState] = React.useState<BaseShareResourceProps>({
    id: '',
    open: false,
    editable: false,
    title: '',
    type: 'project'
  });

  const onClose = () => {
    setState({ ...state, open: false });
  };

  const onDialogOpen = (
    projectId: string,
    shareEditable: boolean,
    projectName: string,
    isFolder: boolean
  ) => {
    setState({
      id: projectId,
      editable: shareEditable,
      open: true,
      title: projectName,
      type: isFolder ? 'folder' : 'project'
    });
  };

  const update = async (id: string, data: any) => {
    await updateProjectName(id, data.name);
  };

  return (
    <MainLayout selectedMenu="projects">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="projects"
            folderId={id}
            load={getProjects}
            add={addProject}
            update={update}
            move={(uuid: string) => {
              window.onbeforeunload = null;
              window.location.href = `/projects/${uuid}?first_display=1`;
              return;
            }}
            delete={deleteProject}
            duplicate={duplicateProject}
            shutdown={shutdownProject}
            onOpenShareDialog={onDialogOpen}
            isOpenShareDialog={state.open}
            showDetailPane={true}
            supportFolder={true}
          />
        </ThemeProvider>
        <ShareResourceDialog {...state} onClose={onClose} />
      </Grid>
    </MainLayout>
  );
};

export default ProjectListPage;
