import * as React from 'react';
import clsx from 'clsx';
import { AxiosError } from 'axios';
import { get, pull, uniq } from 'lodash-es';
import {
  createStyles,
  makeStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Breadcrumbs,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  DialogContentText,
  FormControlLabel,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow as MuiTableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  ThemeProvider
} from '@material-ui/core';
import { CheckCircle, DeleteOutlined, NavigateNext } from '@material-ui/icons';
import reactModal from '@prezly/react-promise-modal';

import { CustomTheme } from '../../theme';
import {
  BaseItem,
  isBaseItem,
  ListItem,
  ListItemType,
  OrganizationItem
} from 'models/dependency';
import { getFolderType, Path } from 'models/folder';
import { ListViewHeader, ListViewRow } from 'ui/listViewTable';
import { HelpButton } from 'components/diagram/ui/HelpDialog';
import { Spinner } from 'components/LoadingSpinner';
import DetailPane from 'ui/detailPane';
import { NavButton } from 'ui/navButton';
import {
  addFolder,
  deleteFolder,
  moveFolderItems,
  updateFolder
} from 'libs/api/folder';
import SvgIcMove from 'components/icons/IcMove';
import { FolderSelectDialog } from 'ui/listView/folderSelectDialog';
import { AuthContext } from 'components/route';
import { getItemID, LinkResource, typeToText } from 'ui/listViewTableCell';
import { ListViewBaseProps } from 'ui/listViewBase';
import { filterReducer } from 'ui/listView/filter/filterReducer';
import { FilterContext } from 'ui/listView/filter/context';

import { FilterAction, FilterState, FilterType } from 'ui/listView/filter/type';
import { initFilterState } from 'ui/listView/filter/initialValue';
import { createQueryParams } from 'ui/listView/filter/form';
import { SearchForm } from 'ui/listView/filter/searchForm';
import { updateScheduleEnabled } from 'libs/api/schedule';
import { TableVirtuoso } from 'react-virtuoso';
import { Dialog } from './common/dialog';
import mainTheme from '../../theme';
import { AccessLevel } from 'libs/accessLevel';
import { ResourceNameDialog } from 'ui/common/resorceNameDialog';

const TableRow = withStyles({
  root: {
    '&$selected, &$selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)'
    }
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.07)'
  }
})(MuiTableRow);

const filterItems = (item: ListItem, filterWord: string): boolean => {
  if ('name' in item) {
    return item.name.indexOf(filterWord) >= 0;
  }

  if ('project_name' in item && 'workflow_name' in item) {
    return (
      item.project_name.indexOf(filterWord) >= 0 ||
      item.workflow_name.indexOf(filterWord) >= 0
    );
  }
  return true;
};

const toolbarStyles = makeStyles((theme: CustomTheme) => ({
  spacer: {
    flex: '1 1 auto'
  },
  actions: {
    flex: '0 0 auto',
    color: theme.palette.text.secondary,
    margin: '5px'
  },
  search: {
    color: '#838383'
  },
  button: {
    height: '36px',
    borderRadius: '3px',
    fontSize: '12px',
    fontWeight: 'bold'
  }
}));

export const TableToolbar: React.FC<{
  folderId?: string;
  newClickHandler: (ev: React.MouseEvent, fid?: string) => void;
  newFolderClickHandler: () => void;
  enableAdd: boolean;
  supportFolder: boolean;
  filterApplied: boolean;
  hideSearch?: boolean;
}> = ({
  folderId,
  newClickHandler,
  newFolderClickHandler,
  enableAdd,
  supportFolder,
  filterApplied,
  hideSearch
}) => {
  const classes = toolbarStyles();
  const { type } = React.useContext(FilterContext);
  const text = typeToText[type];

  return (
    <Toolbar style={{ paddingRight: '45px' }}>
      <div className={classes.actions}>
        {enableAdd && (
          <>
            <NavButton
              arrowDirection="right"
              onClick={(ev) => newClickHandler(ev, folderId)}
              color="nehan"
              variant="contained"
              className={classes.button}
              data-cy={`${text}の新規作成`}
            >
              {text}の新規作成
            </NavButton>
            &nbsp;
            {supportFolder && (
              <NavButton
                onClick={newFolderClickHandler}
                variant="contained"
                color="nehan"
                className={classes.button}
              >
                フォルダ作成
              </NavButton>
            )}
          </>
        )}
      </div>
      <HelpButton manualUrlKey={type} />
      <div className={classes.spacer} />
      {!hideSearch && <SearchForm filterApplied={filterApplied} />}
    </Toolbar>
  );
};

export const SortDirection = {
  asc: 'asc',
  desc: 'desc',
  none: false
} as const;
export type SortDirectionType =
  (typeof SortDirection)[keyof typeof SortDirection];

interface ListViewState {
  anchorPos?: { top: number; left: number };
  menuContext?: string;
  timerId?: number;
  isOpenAddDialog: boolean;
  name: string;
  isRename: boolean;
  filterWord: string; // フィルタリングの機能によっては違う形で再利用するかもしれないので一応残しておく

  isDeletable: boolean;
  loaded: boolean;
  items: ListItem[];
  selectedItem?: ListItem;
  selectedColumnKey: string;
  sortDirection: SortDirectionType;

  // folder
  paths: Path[];
  selectedItemPaths: Path[];
  checkedItems: ListItem[];
  openSelectFolderOnMove: boolean;
  basePath: string;

  filterState: FilterState;
  appliedFilterState: FilterState;
  emails: string[];
  datasourceTypes: string[];

  // 複製する時のフォルダ選択ダイアログ
  openSelectFolderOnCopy: boolean;

  // リソース名編集ダイアログ
  openResourceNameDialog: boolean;
  newResourceName: string;
}

const styles = createStyles({
  navigation: {
    width: '100%',
    padding: '0 14px'
  },

  tableWrapper: {
    overflowX: 'auto',
    flexGrow: 1
  },
  table: {
    flex: 1,
    minWidth: 800
  },
  tableHeader: {
    '& th': {
      height: 40,
      fontSize: 13,
      fontStretch: 'normal',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#888888'
    }
  },
  tableRow: {
    '&:hover': {
      '& td': {
        color: '#000000'
      }
    },
    '&.Mui-selected': {
      '& td': {
        color: '#000000'
      }
    },
    '& td': {
      height: 40,
      fontSize: 13,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#a8a8a8',
      '&:first-child': {
        color: '#000000'
      },
      maxWidth: 400,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '&:last-child td': {
      borderBottom: 'none'
    },
    height: 40
  }
});

type ListViewProps = ListViewBaseProps &
  WithStyles<typeof styles> &
  RouteComponentProps<{}, {}, { reload: boolean }>;

class ListView extends React.Component<ListViewProps, ListViewState> {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const columnOrder = this.getColumnOrder();

    this.state = {
      anchorPos: undefined,
      timerId: undefined,
      menuContext: undefined,
      isOpenAddDialog: false,
      name: '',
      isRename: false,
      filterWord: '',

      isDeletable: true,
      loaded: false,
      items: [],
      selectedColumnKey: columnOrder.key,
      sortDirection: columnOrder.order,

      paths: [],
      selectedItemPaths: [],
      checkedItems: [],
      openSelectFolderOnMove: false,
      basePath: props.basePath ?? `/${props.type}`,

      filterState: initFilterState(this.props.type),
      appliedFilterState: initFilterState(this.props.type),
      emails: [],
      datasourceTypes: [],

      openSelectFolderOnCopy: false,
      openResourceNameDialog: false,
      newResourceName: `新規${typeToText[this.props.type]}`
    };
  }

  componentDidMount() {
    this.load();
    if (this.props.polling) {
      this.setState({
        timerId: window.setInterval(() => {
          if (this.state.timerId != undefined) {
            this.load();
          }
        }, 30 * 1000)
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<ListViewProps>): void {
    const { folderId } = this.props;
    if (prevProps.folderId !== folderId) {
      // フォルダに移動する際、フィルタをクリアするので、読み込む際にフィルタの条件を適用する
      this.load(true);
    }
  }

  componentWillUnmount() {
    if (this.state.timerId != undefined) {
      clearInterval(this.state.timerId);
    }
  }

  async load(filtering?: boolean, init?: boolean) {
    this.setState({ loaded: false, checkedItems: [] });
    let filterState: FilterState;

    if (init) {
      filterState = initFilterState(this.props.type);
    } else if (filtering) {
      filterState = this.state.filterState;
    } else {
      filterState = this.state.appliedFilterState;
    }

    const { data } = await this.props.load(
      this.props.folderId,
      createQueryParams(this.props.type, filterState)
    );
    this.setState({
      loaded: true,
      paths: data?.paths || [],
      items: data?.items || [],
      anchorPos: undefined,
      selectedItem: undefined,
      appliedFilterState: filterState,
      emails: data?.emails || [],
      datasourceTypes: data?.types || []
    });
  }

  getColumnOrder = (): { key: string; order: SortDirectionType } => {
    const columnOrdersStr = localStorage.getItem('columnOrderOnListView');
    if (columnOrdersStr == null) {
      if (this.props.type == 'exports') {
        return { key: 'started_at', order: 'desc' };
      } else if (this.props.type == 'organizations') {
        return { key: 'id', order: 'asc' };
      } else {
        return { key: 'created_at', order: 'desc' };
      }
    } else {
      const columnOrders = JSON.parse(columnOrdersStr);
      return (
        columnOrders[this.props.type] ?? { key: 'created_at', order: 'desc' }
      );
    }
  };

  setColumnOrder = (key: string, order: SortDirectionType) => {
    const columnOrderStr =
      localStorage.getItem('columnOrderOnListView') ?? '{}';
    const columnOrder = JSON.parse(columnOrderStr);

    columnOrder[this.props.type] = { key, order };
    localStorage.setItem('columnOrderOnListView', JSON.stringify(columnOrder));
  };

  handleClickOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    id: string,
    name: string
  ) => {
    this.setState({
      anchorPos: { top: event.clientY, left: event.clientX },
      menuContext: id,
      name,
      isOpenAddDialog: false
    });
  };

  handleClickNewFolder = async () => {
    try {
      const params = await reactModal(({ show, onSubmit, onDismiss }) => (
        <CreateFolderDialog
          show={show}
          onSubmit={onSubmit}
          onDismiss={onDismiss}
        />
      ));
      if (params == undefined) {
        return;
      }
      const { dirname, move } = params;
      if (dirname == undefined || dirname === '') {
        return;
      }
      const { data } = await addFolder(
        getFolderType(this.props.type),
        dirname,
        this.props.folderId || ''
      );
      if (move) {
        this.props.history.push(`${this.state.basePath}/f/${data.uuid}`);
      } else {
        this.load();
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleCloseContextMenu = () => {
    this.setState({
      anchorPos: undefined,
      menuContext: undefined
    });
  };

  handleClickDelete = async () => {
    const { type } = this.props;
    const { menuContext, items } = this.state;
    let deleteStatus: any;
    if (this.props.delete == undefined) {
      return;
    }
    if (menuContext != undefined) {
      const selected = items.find((item) => {
        if (type === 'organizations') {
          return String((item as OrganizationItem).id) === menuContext;
        } else {
          return item.uuid === menuContext;
        }
      });
      try {
        if (selected && selected.is_folder) {
          await deleteFolder(selected.uuid, getFolderType(this.props.type));
        } else {
          if (type === 'organizations') {
            const selectedOrganization = selected as OrganizationItem;
            deleteStatus = await this.props.delete(
              selectedOrganization.namespace
            );
          } else {
            deleteStatus = await this.props.delete(
              menuContext,
              get(selected, 'project_uuid')
            );
          }
        }
      } catch (e) {
        if (e.isAxiosError) {
          alert((e as AxiosError).response?.data);
        } else {
          alert('削除に失敗しました');
        }
      }
      this.load();
    }
    this.setState({
      anchorPos: undefined,
      menuContext: undefined,
      isDeletable: deleteStatus !== 409
    });
  };

  // リソースを作成し、リソースのページに飛ぶ
  addAndJumpResourcePage = async (name: string) => {
    const { add, move, folderId } = this.props;
    if (add == undefined || move == undefined) {
      return;
    }

    try {
      const { data } = await add({
        name,
        folder_id: folderId ?? ''
      });
      this.setState({
        isOpenAddDialog: false,
        isRename: false
      });
      if (data && data.id) {
        move(data.id);
      }
    } catch (e) {
      alert('作成に失敗しました');
    }
    return false;
  };

  // リソースの新規作成ページに飛ぶ
  jumpNewResourcePage = () => {
    const { move, folderId } = this.props;
    if (move == undefined) {
      return;
    }

    // 新規作成ページへの移動
    move(folderId);
    return;
  };

  onClickNewResource = async (ev: React.MouseEvent) => {
    ev.preventDefault();
    const { add, move, nameBeforeAdding } = this.props;
    if (add) {
      if (nameBeforeAdding) {
        this.setState({ openResourceNameDialog: true });
      } else {
        await this.addAndJumpResourcePage(this.state.newResourceName);
      }
    } else if (move) {
      this.jumpNewResourcePage();
    }
  };

  closeResourceNameDialog = () => {
    this.setState({ openResourceNameDialog: false });
  };

  handleUpdateDialog = () => {
    if (this.state.menuContext == undefined) {
      return;
    }
    const contextItem = this.state.items.find(
      (item) => item.uuid === this.state.menuContext
    );
    if (!contextItem || !('access_level' in contextItem)) {
      return;
    }
    if (contextItem.access_level < AccessLevel.Developer) {
      alert('名前の変更権限がありません');
      return;
    }

    this.setState({ isOpenAddDialog: true, isRename: true });
  };

  handleCloseAddDialog = () => {
    this.setState({
      isOpenAddDialog: false,
      menuContext: undefined,
      anchorPos: undefined
    });
  };

  handleCloseDeleteDialog = () => {
    this.setState({ isDeletable: true });
  };

  handleUpdateName = async (ev: React.FormEvent) => {
    ev.preventDefault();
    const { update } = this.props;
    if (update == undefined) {
      return;
    }
    const { menuContext, name, items } = this.state;
    if (menuContext != undefined && name != undefined) {
      const body = { name };
      const selected = items.find((item) => item.uuid === menuContext);
      if (selected) {
        body['projectId'] = get(selected, 'project_uuid');
      }

      await update(menuContext, body);
      this.load();
    }
    this.setState({
      isOpenAddDialog: false,
      name: '',
      anchorPos: undefined,
      isRename: false
    });
    return false;
  };

  handleDuplicate = async () => {
    this.setState({ openSelectFolderOnCopy: true });
  };

  handleNewNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.currentTarget.value });
  };

  handleKeywordFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterWord: event.currentTarget.value });
  };

  handleClickRow = (item: ListItem) => {
    this.setState({ selectedItem: item });
  };

  handleClickColumn = (key: string) => {
    this.setState({ selectedColumnKey: key });
    this.setState((prevState) => {
      if (this.state.selectedColumnKey != key) {
        const order = SortDirection.desc;
        this.setColumnOrder(key, order);
        return { sortDirection: order };
      }

      switch (prevState.sortDirection) {
        case SortDirection.asc: {
          const order: SortDirectionType = SortDirection.none;
          this.setColumnOrder(key, order);
          return { sortDirection: order };
        }
        case SortDirection.desc: {
          const order = SortDirection.asc;
          this.setColumnOrder(key, order);
          return { sortDirection: order };
        }
        case SortDirection.none: {
          const order = SortDirection.desc;
          this.setColumnOrder(key, order);
          return { sortDirection: order };
        }
      }
    });
  };

  onCheckChanged = (checked: boolean, item: ListItem) => {
    const checkedItems = checked
      ? uniq([...this.state.checkedItems, item])
      : pull([...this.state.checkedItems], item);
    this.setState({ checkedItems });
    this.props.onChangeCheckedIds &&
      this.props.onChangeCheckedIds(checkedItems.map((v) => v.uuid));
  };

  onSelectAll = () => {
    const checkedItems =
      this.state.checkedItems.length > 0
        ? []
        : this.state.items.filter((i) => filterItems(i, this.state.filterWord));

    this.setState({ checkedItems });
    this.props.onChangeCheckedIds &&
      this.props.onChangeCheckedIds(checkedItems.map((v) => v.uuid));
  };

  moveItems = () => {
    this.setState({ openSelectFolderOnMove: true });
  };

  onSelectFolderCloseOnMove = async (folderId: string | null) => {
    this.setState({ openSelectFolderOnMove: false });

    if (folderId === null) {
      return;
    }
    try {
      // 対象のアイテムを集める
      const items = this.state.checkedItems.map((i) => ({
        id: i.uuid,
        type: i.is_folder ? 'folder' : getFolderType(this.props.type)
      }));
      await moveFolderItems(items, folderId);
    } catch (e) {
      if (e.isAxiosError) {
        alert((e as AxiosError).response?.data);
      } else {
        console.log(e);
        alert(e);
      }
    }
    this.load();
  };

  onSelectFolderCloseOnCopy = async (folderId: string | null) => {
    if (folderId === null) {
      this.setState({ openSelectFolderOnCopy: false });
      return;
    }
    if (
      this.state.menuContext !== undefined &&
      this.props.duplicate != undefined
    ) {
      try {
        await this.props.duplicate(this.state.menuContext, folderId);
        alert('オブジェクトが複製されました');
      } catch (e) {
        alert('複製に失敗しました');
      }
      this.load();
    }
    this.setState({
      isOpenAddDialog: false,
      anchorPos: undefined,
      openSelectFolderOnCopy: false
    });
  };

  table = () => {
    const {
      classes,
      type,
      enableShare,
      showProject,
      onClickItem,
      disableCheck,
      folderOnly,
      onClickItemLink,
      disableItemClick,
      disableItem,
      onOpenShareDialog
    } = this.props;
    const {
      loaded,
      items,
      filterWord,
      selectedItem,
      selectedColumnKey,
      sortDirection,
      basePath,
      checkedItems
    } = this.state;
    const text = typeToText[type];

    if (!loaded) {
      return (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Spinner size={24} />
        </div>
      );
    }

    let data = items.filter((item) => filterItems(item, filterWord));
    if (disableItem) {
      data = data.filter((item) => !disableItem(item));
    }

    data = data.sort((item1, item2) => {
      if (selectedColumnKey == undefined) {
        return 0;
      }

      if (item1.is_folder && !item2.is_folder) {
        return -1;
      } else if (!item1.is_folder && item2.is_folder) {
        return 1;
      }

      switch (sortDirection) {
        case SortDirection.asc:
          return item1[selectedColumnKey] < item2[selectedColumnKey] ? -1 : 1;
        case SortDirection.desc:
          return item1[selectedColumnKey] < item2[selectedColumnKey] ? 1 : -1;
        case SortDirection.none:
          return 0;
      }
      return item1[selectedColumnKey] < item2[selectedColumnKey] ? -1 : 1;
    });

    return (
      <TableVirtuoso
        components={{
          Table: (props) => (
            <Table
              {...props}
              className={classes.table}
              stickyHeader={true}
              size="small"
            />
          ),
          TableHead: React.forwardRef((props, ref) => (
            <TableHead ref={ref} {...props} className={classes.tableHeader} />
          )),
          // @ts-ignore
          TableRow: ({ item, ...props }) => {
            const id = getItemID(type, item);
            const selectedItemId = selectedItem
              ? getItemID(type, selectedItem)
              : undefined;
            return (
              <TableRow
                data-cy={`${type}-${id}`}
                hover={true}
                selected={id === selectedItemId}
                className={classes.tableRow}
                onClick={() => {
                  this.handleClickRow(item);
                  if (!disableItemClick && onClickItem) {
                    this.clearForm(item.uuid, item.is_folder);
                    onClickItem(item);
                  }
                }}
                {...props}
              />
            );
          },
          TableBody: React.forwardRef((props, ref) => (
            <TableBody {...props} ref={ref} />
          ))
        }}
        fixedHeaderContent={() => (
          <TableRow>
            <ListViewHeader
              type={type}
              showProject={showProject}
              text={text}
              checked={checkedItems.length === items.length}
              indeterminate={
                checkedItems.length > 0 && checkedItems.length !== items.length
              }
              disableCheck={disableCheck}
              onSelectAll={this.onSelectAll}
              onClickColumn={this.handleClickColumn}
              selectedColumnKey={selectedColumnKey}
              sortDirection={sortDirection}
            />
          </TableRow>
        )}
        data={data}
        itemContent={(_, item) => {
          return (
            <Row
              item={item}
              type={type}
              enableShare={enableShare}
              showProject={showProject}
              onClickItem={onClickItem}
              disableCheck={disableCheck}
              folderOnly={folderOnly}
              onClickItemLink={onClickItemLink}
              basePath={basePath}
              clearForm={this.clearForm}
              onOpenShareDialog={onOpenShareDialog}
              checkedItems={checkedItems}
              handleClickOpenMenu={this.handleClickOpenMenu}
              onCheckChanged={this.onCheckChanged}
              onScheduleChanged={this.onScheduleChanged}
            />
          );
        }}
      />
    );
  };

  onScheduleChanged = async (id: string, enabled: boolean) => {
    await updateScheduleEnabled(id, enabled);
    this.load();
  };

  onDeleteItems = async () => {
    try {
      // 対象のアイテムを集める
      const items = this.state.checkedItems;
      if (
        !confirm(
          `${items.length}件のオブジェクトを削除しますがよろしいですか？`
        )
      ) {
        return;
      }
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.is_folder) {
          await deleteFolder(item.uuid, getFolderType(this.props.type));
        } else {
          this.props.delete &&
            (await this.props.delete(
              get(item, 'uuid', ''),
              get(item, 'project_uuid')
            ));
        }
      }
    } catch (e) {
      if (e.isAxiosError) {
        alert((e as AxiosError).response?.data);
      } else {
        console.log(e);
        alert(e);
      }
    }
    this.load();
  };

  onClickFolder = (id: string) => {
    this.clearForm(id, true);
    if (this.props.onClickFolder) {
      this.props.onClickFolder(id);
    } else {
      const pathname = getFolderPath(this.state.basePath, id);
      this.props.history.push(pathname);
    }
  };

  handleRenameFolder = async () => {
    const contextItem = this.state.items.find(
      (item) => item.uuid === this.state.menuContext
    );
    if (!contextItem || !('access_level' in contextItem)) {
      return;
    }
    if (contextItem.access_level < AccessLevel.Developer) {
      alert('名前の変更権限がありません');
      return;
    }
    const params = await reactModal(({ show, onSubmit, onDismiss }) => (
      <RenameFolderDialog
        defaultName={get(contextItem, 'name', '')}
        show={show}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
      />
    ));
    if (params == undefined) {
      return;
    }
    const { dirname } = params;
    if (dirname == undefined || dirname === '') {
      return;
    }
    try {
      await updateFolder(contextItem.uuid, dirname);
      this.load();
    } catch (e) {
      if (e.isAxiosError) {
        alert((e as AxiosError).response?.data);
      } else {
        alert('名前の変更に失敗しました');
      }
    }
    this.setState({ anchorPos: undefined });
  };

  filterDispatch = (prevState: FilterState, action: FilterAction) => {
    const filterState = filterReducer(prevState, action);
    this.setState({ filterState });
  };

  clearForm = (
    folderId: string,
    isFolder: boolean | undefined | null,
    pathname?: string
  ) => {
    this.filterDispatch(this.state.filterState, {
      type: FilterType.Clear,
      payload: this.props.type
    });

    if (isFolder && folderId === this.props.folderId) {
      this.load(false, true);
    }
    if (pathname) {
      this.props.history.push(pathname);
    }
  };

  setSelectedItemPaths = (selectedItemPaths: Path[]) => {
    this.setState({ selectedItemPaths });
  };

  render() {
    const {
      type,
      showDetailPane,
      supportFolder,
      isAdminPage,
      hideToolbar,
      hideSearch
    } = this.props;
    const {
      paths,
      selectedItemPaths,
      anchorPos,
      isRename,
      checkedItems,
      items,
      menuContext,
      selectedItem,
      filterState,
      appliedFilterState,
      emails,
      datasourceTypes,
      openResourceNameDialog,
      newResourceName
    } = this.state;
    const buttonName = isRename ? '更新' : '作成';

    const text = typeToText[type];
    const width = showDetailPane ? '70%' : '100%';
    const contextItem = items.find((item) => item.uuid === menuContext);

    return (
      <>
        <div
          style={{
            width,
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          data-cy="listview"
        >
          {!hideToolbar && (
            <FilterContext.Provider
              value={{
                state: filterState,
                dispatch: this.filterDispatch,
                type,
                isAdminPage,
                items,
                emails,
                datasourceTypes,
                load: (filtering?: boolean) => this.load(filtering)
              }}
            >
              <TableToolbar
                folderId={this.props.folderId}
                newClickHandler={this.onClickNewResource}
                newFolderClickHandler={this.handleClickNewFolder}
                enableAdd={
                  this.props.add != undefined || this.props.move != undefined
                }
                supportFolder={supportFolder}
                filterApplied={appliedFilterState['filtering']}
                hideSearch={hideSearch}
              />
              <ResourceNameDialog
                open={openResourceNameDialog}
                type="reports"
                name={newResourceName}
                title={`${typeToText[type]}の新規作成`}
                cancelButtonLabel="キャンセル"
                onChange={this.addAndJumpResourcePage}
                onClose={this.closeResourceNameDialog}
              />
            </FilterContext.Provider>
          )}
          {supportFolder && (
            <ManagerToolbar
              paths={paths}
              onClickFolder={this.onClickFolder}
              editMode={checkedItems.length > 0}
              filtering={appliedFilterState['filtering']}
              onUncheck={this.onSelectAll}
              onMove={this.moveItems}
              onDelete={this.onDeleteItems}
            />
          )}
          {this.table()}
          <Menu
            id="project-menu"
            data-cy="listview-context-menu"
            anchorPosition={anchorPos}
            anchorReference="anchorPosition"
            open={Boolean(anchorPos)}
            onClose={this.handleCloseContextMenu}
          >
            {this.props.update != undefined && !contextItem?.is_folder && (
              <MenuItem onClick={this.handleUpdateDialog}>名前の変更</MenuItem>
            )}
            {contextItem?.is_folder && (
              <MenuItem onClick={this.handleRenameFolder}>
                フォルダ名の変更
              </MenuItem>
            )}
            {this.props.duplicate != undefined &&
              !get(contextItem, 'is_folder', false) && (
                <MenuItem onClick={this.handleDuplicate}>複製</MenuItem>
              )}
            <MenuItem
              onClick={() => {
                if (window.confirm(this.state.name + ' を削除しますか？')) {
                  this.handleClickDelete();
                }
              }}
            >
              <Typography variant="inherit" color="secondary">
                削除
              </Typography>
            </MenuItem>
          </Menu>
          <NameDialog
            name={this.state.name}
            open={this.state.isOpenAddDialog}
            text={text}
            buttonName={buttonName}
            isRename={isRename}
            onClose={this.handleCloseAddDialog}
            onChange={this.handleNewNameChanged}
            onSubmit={(ev) => {
              if (isRename) {
                this.handleUpdateName(ev);
              }
            }}
          />
          <Dialog
            open={!this.state.isDeletable}
            title={`${text}の削除`}
            onClose={this.handleCloseDeleteDialog}
            hideCancelButton
            OKButton={{
              label: '閉じる',
              onClick: this.handleCloseDeleteDialog,
              autoFocus: true
            }}
          >
            <DialogContentText>
              ワークフローの実行中のため、削除できません
            </DialogContentText>
          </Dialog>
          {showDetailPane &&
            selectedItem &&
            isBaseItem(selectedItem) &&
            supportFolder &&
            appliedFilterState['filtering'] && (
              <PathNavigation
                type={type}
                item={selectedItem}
                paths={selectedItemPaths}
                onClickFolder={this.onClickFolder}
              />
            )}
        </div>
        {showDetailPane && (
          <div style={{ width: '30%' }}>
            <DetailPane
              type={type}
              item={this.state.selectedItem}
              setPaths={this.setSelectedItemPaths}
            />
          </div>
        )}
        <FolderSelectDialog
          type={type}
          mode="move"
          defaultFolderId={this.props.folderId || ''}
          load={this.props.load}
          open={this.state.openSelectFolderOnMove}
          onClose={this.onSelectFolderCloseOnMove}
        />
        <FolderSelectDialog
          type={type}
          mode="copy"
          defaultFolderId={this.props.folderId || ''}
          load={this.props.load}
          open={this.state.openSelectFolderOnCopy}
          onClose={this.onSelectFolderCloseOnCopy}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(ListView));

export const NameDialog: React.FC<{
  open: boolean;
  text: string;
  buttonName: string;
  isRename: boolean;
  name: string;
  onClose: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (ev: React.FormEvent) => void;
}> = ({
  open,
  onClose,
  onChange,
  onSubmit,
  text,
  buttonName,
  isRename,
  name
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={`${text}の${buttonName}`}
      onSubmit={onSubmit}
      OKButton={{
        label: isRename ? '更新' : '作成して開始',
        type: 'submit',
        disabled: name.length === 0
      }}
    >
      <DialogContentText>
        {text}を{buttonName}
        します。
        <br />
        名前を入力してください。
      </DialogContentText>
      <TextField
        autoFocus={true}
        margin="dense"
        id="name"
        label={`${text}`}
        value={name}
        type="text"
        fullWidth={true}
        onChange={onChange}
      />
    </Dialog>
  );
};

export function getFolderPath(basePath: string, uuid?: string): string {
  if (uuid === '' || uuid == undefined) {
    return `${basePath}`;
  }
  return `${basePath}/f/${uuid}`;
}

const CreateFolderDialog: React.FC<{
  show: boolean;
  onSubmit: (params: { dirname: string; move: boolean }) => void;
  onDismiss: () => void;
}> = ({ show, onSubmit, onDismiss }) => {
  const [text, setText] = React.useState('');
  const handleChange = React.useCallback((ev) => {
    setText(ev.target.value);
  }, []);
  const handleSubmit: React.FormEventHandler = React.useCallback(
    (ev) => {
      onSubmit({ dirname: text, move: true });
      ev.preventDefault();
    },
    [onSubmit, text]
  );
  const handleCreate = React.useCallback(() => {
    onSubmit({ dirname: text, move: false });
  }, [onSubmit, text]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Dialog
        open={show}
        onClose={onDismiss}
        title="フォルダの作成"
        OKButton={{
          label: '作成',
          onClick: handleCreate,
          disabled: text === ''
        }}
        secondaryOKButton={{
          label: '作成して移動',
          type: 'submit',
          disabled: text === ''
        }}
        onSubmit={handleSubmit}
      >
        <form onSubmit={handleSubmit}>
          <DialogContentText>名前を入力してください。</DialogContentText>
          <TextField
            autoFocus={true}
            margin="dense"
            label="フォルダ名"
            value={text}
            type="text"
            fullWidth={true}
            onChange={handleChange}
          />
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

const RenameFolderDialog: React.FC<{
  defaultName: string;
  show: boolean;
  onSubmit: (params: { dirname: string }) => void;
  onDismiss: () => void;
}> = ({ defaultName, show, onSubmit, onDismiss }) => {
  const [text, setText] = React.useState(defaultName);
  const handleChange = React.useCallback((ev) => {
    setText(ev.target.value);
  }, []);
  const handleSubmit: React.FormEventHandler = React.useCallback(
    (ev) => {
      onSubmit({ dirname: text });
      ev.preventDefault();
    },
    [onSubmit, text]
  );

  return (
    <ThemeProvider theme={mainTheme}>
      <Dialog
        open={show}
        onClose={onDismiss}
        title="フォルダ名の変更"
        onSubmit={handleSubmit}
        OKButton={{
          label: '更新',
          type: 'submit',
          disabled: text === ''
        }}
      >
        <DialogContentText>名前を入力してください。</DialogContentText>
        <TextField
          autoFocus={true}
          margin="dense"
          label="フォルダ名"
          value={text}
          type="text"
          fullWidth={true}
          onChange={handleChange}
        />
      </Dialog>
    </ThemeProvider>
  );
};

const toolbarStyle = makeStyles({
  navigationText: {
    fontWeight: 'bold'
  },
  pointer: {
    cursor: 'pointer'
  },
  title: {
    height: 64,
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    '& button:not(:last-child)': {
      marginRight: 10
    },
    fontSize: 14,
    fontWeight: 'bold',
    color: '#525252'
  },
  footerTitle: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    '& button:not(:last-child)': {
      marginRight: 10
    },
    fontSize: 14,
    fontWeight: 'bold',
    color: '#525252'
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#525252'
  },
  editMode: {
    backgroundColor: '#fffbed'
  },
  spacer: {
    flex: '1 1 auto'
  },
  hideDivider: {
    backgroundColor: '#fff'
  },
  hide: {
    display: 'none'
  }
});

const Checkbox = withStyles({
  root: {
    color: '#b8b8b8',
    '&$checked': {
      color: '#344955'
    }
  },
  checked: {}
})((props: CheckboxProps) => <MuiCheckbox color="default" {...props} />);

const ManagerToolbar: React.FC<{
  paths: Path[];
  onClickFolder: (fid: string) => void;
  editMode: boolean;
  filtering: boolean;
  onUncheck: () => void;
  onMove: () => void;
  onDelete: () => void;
}> = ({
  paths,
  onClickFolder,
  editMode,
  filtering,
  onUncheck,
  onMove,
  onDelete
}) => {
  const classes = toolbarStyle();

  return (
    <>
      {filtering && (
        <Typography key="top" className={classes.title}>
          検索結果
        </Typography>
      )}
      <div
        className={clsx(classes.title, {
          [classes.editMode]: editMode,
          [classes.hide]: !editMode && filtering
        })}
      >
        {!editMode && !filtering && (
          <>
            <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
              {paths.map((p, i) =>
                i !== paths.length - 1 ? (
                  <Link
                    className={classes.pointer}
                    key={p.uuid || 'top'}
                    onClick={() => onClickFolder(p.uuid)}
                  >
                    {p.name}
                  </Link>
                ) : (
                  <Typography key="top" className={classes.navigationText}>
                    {p.name}
                  </Typography>
                )
              )}
            </Breadcrumbs>
          </>
        )}
        {editMode && (
          <>
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Checkbox checkedIcon={<CheckCircle />} checked={true} />
              }
              label="選択解除"
              onChange={onUncheck}
            />
            <div className={classes.spacer} />
            <Tooltip title="移動">
              <IconButton onClick={onMove}>
                <SvgIcMove />
              </IconButton>
            </Tooltip>
            <Tooltip title="削除">
              <IconButton onClick={onDelete}>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
};

const PathNavigation: React.FC<{
  type: ListItemType;
  item: BaseItem;
  paths: Path[];
  onClickFolder: (fid: string) => void;
}> = ({ type, item, paths, onClickFolder }) => {
  const classes = toolbarStyle();

  return (
    <div className={clsx(classes.footerTitle)}>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        style={{
          backgroundColor: '#fafafa'
        }}
      >
        {paths.map((p) => (
          <Link
            className={classes.pointer}
            key={p.uuid}
            onClick={() => onClickFolder(p.uuid)}
          >
            {p.name}
          </Link>
        ))}
        <LinkResource type={type} item={item} onClickFolder={onClickFolder} />
      </Breadcrumbs>
    </div>
  );
};

const Row = ({
  item,
  type,
  enableShare,
  showProject,
  onClickItem,
  disableCheck,
  folderOnly,
  onClickItemLink,
  basePath,
  clearForm,
  onOpenShareDialog,
  checkedItems,
  handleClickOpenMenu,
  onCheckChanged,
  onScheduleChanged
}) => {
  return (
    <ListViewRow
      item={item}
      type={type}
      enableShare={enableShare}
      showProject={showProject}
      onClickItem={onClickItem}
      onOpenShareDialog={onOpenShareDialog}
      onOpenMenu={handleClickOpenMenu}
      onCheckChanged={onCheckChanged}
      onScheduleChanged={onScheduleChanged}
      checked={checkedItems.includes(item)}
      disableCheck={disableCheck}
      folderOnly={folderOnly}
      basePath={basePath}
      onClickLink={onClickItemLink}
      clearForm={clearForm}
    />
  );
};
