import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  InputBase as MuiInputBase,
  TextField as MuiTextField,
  TextFieldProps
} from '@material-ui/core';

const styles = {
  root: {
    minHeight: 39,
    backgroundColor: '#e9ecee',
    borderRadius: 4,
    paddingLeft: 8
  },
  input: {
    padding: 8,
    paddingLeft: 0
  }
};
const useStyles = makeStyles(styles);

export const InputBase = withStyles(styles)(MuiInputBase);

export const TextField: React.FC<TextFieldProps> = React.forwardRef(
  ({ InputProps: origInputProps, ...props }, ref) => {
    const classes = useStyles();
    const InputProps = React.useMemo(
      () => ({ ...origInputProps, disableUnderline: true, classes: classes }),
      [origInputProps]
    );
    return <MuiTextField InputProps={InputProps} ref={ref} {...props} />;
  }
);

TextField.displayName = 'TextField';
