import * as React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory, useParams } from 'react-router-dom';
import MainLayout from 'components/layouts/main';
import ShareResourceDialog, {
  BaseShareResourceProps
} from 'components/dialogs/shareResource';
import ListView from 'components/ui/listView';
import { deleteVariable, getVariableList, updateVariabletName } from 'libs/api';
import { projectTheme } from '../../theme';

const VariableListPage = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const [state, setState] = React.useState<BaseShareResourceProps>({
    type: 'variable',
    id: '',
    open: false,
    editable: false,
    title: ''
  });

  const onClose = () => {
    setState({ ...state, open: false });
  };

  const onDialogOpen = (
    variableId: string,
    shareEditable: boolean,
    title: string,
    isFolder: boolean
  ) => {
    setState({
      type: isFolder ? 'folder' : 'variable',
      id: variableId,
      editable: shareEditable,
      open: true,
      title
    });
  };

  const update = async (id: string, data: any) => {
    await updateVariabletName(id, data.name);
  };

  return (
    <MainLayout selectedMenu="variables">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="variables"
            folderId={id}
            load={getVariableList}
            move={async () =>
              history.push({
                pathname: '/variables/new',
                search: id ? `?folderId=${id}` : ''
              })
            }
            delete={deleteVariable}
            update={update}
            enableShare={true}
            onOpenShareDialog={onDialogOpen}
            showDetailPane={true}
            supportFolder={true}
          />
        </ThemeProvider>
        <ShareResourceDialog {...state} onClose={onClose} />
      </Grid>
    </MainLayout>
  );
};

export default VariableListPage;
