import { QueryParams } from 'components/ui/listViewBase';
import { _delete, _get, _post, _put } from 'libs/api/base';
import {
  NotificationGetRequest,
  NotificationListGetRequest,
  NotificationPutRequest,
  Timing
} from 'models/notification/notification';
import { DestinationType } from 'models/notificationDst/base';
import { ScheduleOutput } from 'models/schedule';

export function notifyTest(body: NotificationPutRequest & { timing: Timing }) {
  return _post('/test_notification', body);
}

export function putNotification(
  body: NotificationPutRequest,
  folderId?: string | null
) {
  return _put<NotificationGetRequest>('/notifications', body, {
    params: { folderId }
  });
}

export function getNotification(uuid: string) {
  return _get<NotificationGetRequest>(`/notifications/${uuid}`);
}

export function getNotifications(folderId?: string, queryParams?: QueryParams) {
  return _get(`/notifications`, { params: { folderId, ...queryParams } });
}

export function getAdminNotifications(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get(`/admin/notifications`, { params: { folderId, ...queryParams } });
}

export function getScheduleNotifications(
  schedule_id: string,
  queryParams?: QueryParams
) {
  return _get<NotificationListGetRequest[]>(`/schedule_notifications`, {
    params: { schedule_id, ...queryParams }
  });
}

export function deleteNotification(uuid: string) {
  return _delete(`/notifications/${uuid}`);
}

export function getAttachableScheduleOutputs(uuid: string) {
  return _get<ScheduleOutput[]>(`/schedules/${uuid}/attachable_outputs`);
}

export function deleteNotificationMember(
  nid: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/notifications/${nid}/members`, { id, is_group });
}

export function duplicateNotification(uuid: string, folderId?: string) {
  return _post('/notifications/' + uuid + '/duplicate', null, {
    params: { folderId }
  });
}

export function getNotificationUserDstTypes() {
  return _get<DestinationType[]>(`/notification_user_dst_types`);
}

export function updateNotificationName(id: string, name: string) {
  return _put(`/notifications/${id}/name`, { name });
}
