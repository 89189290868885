import {
  Schedule,
  ScheduleHistory,
  ScheduleOutput,
  ScheduleRelationship
} from 'models/schedule';
import { _delete, _get, _post, _put } from 'libs/api/base';
import { QueryParams } from 'ui/listViewBase';
import { ListItem } from 'models/dependency';
import { Path } from 'models/folder';

export function getTemporarySchedule(
  type: string,
  id: string,
  ids: string[],
  current?: Schedule,
  index?: number
) {
  return _post<{ schedule: Schedule; outputs: Record<string, ScheduleOutput> }>(
    '/schedule/temporary',
    {
      type,
      id,
      ids,
      current,
      index
    }
  );
}

export function resetScheduleRelationship(current: ScheduleRelationship) {
  return _post<ScheduleRelationship>('/schedule/reset_relationship', current);
}

export function getManualSchedule(
  type: string,
  ids: string[],
  current?: Schedule,
  index?: number
) {
  return _post<{ schedule: Schedule; outputs: Record<string, ScheduleOutput> }>(
    '/schedule/manual',
    {
      type,
      ids,
      current,
      index
    }
  );
}

export function putSchedule(s: Schedule, folderId?: string | null) {
  return _put<Schedule>('/schedule', s, { params: { folderId } });
}

export function getSchedules(folderId?: string, queryParams?: QueryParams) {
  return _get<{
    items: ListItem[];
    paths: Path[];
    emails: string[];
  }>('/schedules', {
    params: { folderId, ...queryParams }
  });
}

export function getAdminSchedules(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get<{
    items: ListItem[];
    paths: Path[];
    emails: string[];
  }>('/admin/schedules', {
    params: { folderId, ...queryParams }
  });
}

export interface executeScheduleParam {
  full: boolean;
  relationship_index?: number;
  datasource_id?: string;
  project_id?: string;
  workflow_id?: number;
}

export function executeSchedule(uuid: string, params: executeScheduleParam) {
  return _post(`/schedules/${uuid}/execute`, params);
}

export function cancelSchedule(uuid: string) {
  return _post(`/schedules/${uuid}/cancel`, {});
}

export function getProjectSchedules(project_id: string) {
  return _get<Schedule[]>('/schedules', { params: { project_id } });
}

export function getDatasourceSchedules(datasource_id: string) {
  return _get<Schedule[]>('/schedules', { params: { datasource_id } });
}

export function getReportSchedules(report_id: string) {
  return _get<Schedule[]>('/schedules', { params: { report_id } });
}

export function getSchedule(uuid: string) {
  return _get<{
    schedule: Schedule;
    outputs: ScheduleOutput[];
    running: boolean;
  }>('/schedules/' + uuid);
}

export function duplicateSchedule(uuid: string, folderId?: string) {
  return _post('/schedules/' + uuid + '/duplicate', null, {
    params: { folderId }
  });
}

export function deleteSchedule(uuid: string) {
  return _delete(`/schedules/${uuid}`);
}

export function getScheduleHistories(uuid: string, full: boolean) {
  return _get<ScheduleHistory[]>('/schedules/' + uuid + '/histories', {
    params: { full }
  });
}

export function getScheduleHistory(uuid: string) {
  return _get<ScheduleHistory>('/schedule_histories/' + uuid);
}

export function deleteScheduleMember(
  sid: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/schedules/${sid}/members`, { id, is_group });
}

export function updateScheduleEnabled(id: string, enabled: boolean) {
  return _put(`/schedules/${id}/enabled`, { enabled });
}

export async function getScheduleReports(
  scheduleId: string,
  folderId?: string
) {
  return _get(`/schedules/${scheduleId}/reports`, {
    params: { folderId }
  });
}

export function updateScheduleName(id: string, name: string) {
  return _put(`/schedules/${id}/name`, { name });
}
