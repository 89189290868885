import * as React from 'react';
import MainLayout from 'components/layouts/main';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ShareResourceDialog, {
  BaseShareResourceProps
} from 'components/dialogs/shareResource';
import ListView from 'components/ui/listView';
import {
  deleteNotification,
  duplicateNotification,
  getNotifications,
  updateNotificationName
} from 'libs/api';
import { projectTheme } from '../../../theme';
import { useHistory, useParams } from 'react-router-dom';

const NotificationListPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [state, setState] = React.useState<BaseShareResourceProps>({
    id: '',
    open: false,
    editable: false,
    title: '',
    type: 'notification'
  });

  const onClose = () => {
    setState({ ...state, open: false });
  };

  const onDialogOpen = (
    connectionId: string,
    shareEditable: boolean,
    projectName: string,
    isFolder: boolean
  ) => {
    setState({
      id: connectionId,
      editable: shareEditable,
      open: true,
      title: projectName,
      type: isFolder ? 'folder' : 'notification'
    });
  };

  const update = async (id: string, data: any) => {
    await updateNotificationName(id, data.name);
  };

  return (
    <MainLayout selectedMenu="notifications">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="notifications"
            folderId={id}
            load={getNotifications}
            move={(fid) => {
              history.push({
                pathname: '/notifications/new',
                search: new URLSearchParams({
                  folder_id: fid || '',
                  first_display: '1'
                }).toString()
              });
            }}
            delete={deleteNotification}
            duplicate={duplicateNotification}
            update={update}
            onOpenShareDialog={onDialogOpen}
            isOpenShareDialog={state.open}
            showDetailPane={true}
            supportFolder={true}
          />
        </ThemeProvider>
        <ShareResourceDialog {...state} onClose={onClose} />
      </Grid>
    </MainLayout>
  );
};

export default NotificationListPage;
