import * as React from 'react';

import { createStyles, Theme } from '@material-ui/core/styles';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@material-ui/core';

import { UserRole } from 'libs/accessLevel';
import {
  Camera,
  SettingsInputComponent,
  Storage,
  TextFormat
} from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcSqlBuilder from 'components/icons/IcSqlBuilder';
import SvgIcDashboard from 'components/icons/IcDashboard';
import { AuthContext } from 'components/route';
import { SvgIcProjectHyper } from 'components/icons/IcProjectHyper';
import { SvgIcProject } from 'components/icons/IcProject';
import { LinkForceRemount } from 'ui/linkForceRemount';
import { SvgIcDatasource } from 'components/icons/IcDatasource';
import { LinkProps } from 'react-router-dom';
import { IconSchedule } from 'components/icons/IcSchedule';
import { SvgIcNotify } from 'components/icons/IcNotify';
import {
  MdGroups,
  MdManageAccounts,
  MdOutlineCreditCard,
  MdSettings
} from 'react-icons/md';
import { IconExport } from './icons/IcExport';
import { IconConnection } from './icons/IcConnection';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  selected: boolean;
  primary: string;
  to: string;
}

const listItemStyles = makeStyles({
  root: {
    '&.Mui-selected': {
      backgroundColor: '#4d4d4d19'
    }
  }
});

function ListItemLink(props: ListItemLinkProps) {
  const classes = listItemStyles();
  const { icon, primary, to } = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((linkProps, ref) => (
        <LinkForceRemount ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );

  return (
    <li data-cy={props['data-cy']}>
      <ListItem
        classes={{ root: classes.root }}
        button={true}
        component={CustomLink}
        selected={props.selected}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const drawerWidth = 240;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#eee',
      '& .Mui-selected': {
        borderLeft: '8px solid #e2762a',
        paddingLeft: '3px !important',
        '& span': {
          fontWeight: 'bold'
        }
      },
      '& .MuiList-subheader': {
        marginTop: '25px'
      },
      '& .MuiListSubheader-root': {
        height: '20px',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#838383'
      },
      '& .MuiListSubheader-gutters': {
        paddingLeft: '19px'
      },
      '& .MuiListItem-root': {
        paddingTop: '10px',
        paddingBottom: '10px',
        marginLeft: '8px',
        width: 'auto'
      },
      '& .MuiListItemIcon-root': {
        minWidth: '30px',
        minHeight: '30px',
        justifyContent: 'center',
        alignItems: 'center'
      },
      '& .MuiListItem-gutters': {
        paddingLeft: '11px'
      },
      '& span': {
        fontSize: '13px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000'
      },
      '& svg': {
        color: '#000'
      }
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3)
    },
    divider: {
      marginBottom: 3,
      marginLeft: 19,
      marginRight: 16
    }
  })
);

export type SelectedMenu =
  | 'info'
  | 'manual'
  | 'user'
  | 'projects'
  | 'datasources'
  | 'exports'
  | 'schedules'
  | 'connections'
  | 'notifications'
  | 'notification_dsts'
  | 'builders'
  | 'reports'
  | 'variables'
  | 'admin/users'
  | 'admin/user_groups'
  | 'admin/storage_usage'
  | 'admin/payment'
  | 'admin/projects'
  | 'admin/datasources'
  | 'admin/builders'
  | 'admin/schedules'
  | 'admin/connections'
  | 'admin/variables'
  | 'admin/reports'
  | 'admin/notifications'
  | 'admin/notification_dsts'
  | 'admin/organizations'
  | 'admin/backends'
  | 'admin/project_export'
  | 'admin/builder_export'
  | 'admin/project_import'
  | 'admin/builder_import';

export interface MainDrawerProps {
  selectedMenu: SelectedMenu;
}

const mainDrawer: React.FC<MainDrawerProps> = ({ selectedMenu }) => {
  const { user } = React.useContext(AuthContext);
  const showAdminMenu = user.organization.role >= UserRole.Admin;
  const showNehanAdminMenu = user.organization.role >= UserRole.NehanAdmin;
  const showResources = !user.organization.private_aws;

  const classes = styles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItemLink
          to="/projects"
          selected={selectedMenu === 'projects'}
          icon={
            user.organization.feature_spark ? (
              <SvgIcProjectHyper />
            ) : (
              <SvgIcProject />
            )
          }
          primary="データを分析する"
          data-cy="drawer-project-list-link"
        />
        <ListItemLink
          to="/user"
          selected={selectedMenu === 'user'}
          icon={<MdSettings size="1.5em" />}
          primary="アカウント設定"
          data-cy="drawer-account-setting-link"
        />
        {!import.meta.env.VITE_HIDE_SAMPLES && (
          <ListItemLink
            to="/manual"
            selected={selectedMenu === 'manual'}
            icon={<Camera />}
            primary="マニュアル"
            data-cy="drawer-manual-link"
          />
        )}
      </List>
      <List subheader={<ListSubheader>データを作成・管理する</ListSubheader>}>
        <Divider className={classes.divider} variant="middle" />
        <ListItemLink
          to="/datasources"
          selected={selectedMenu === 'datasources'}
          icon={<SvgIcDatasource />}
          primary="データソース"
          data-cy="drawer-datasource-list-link"
        />
        <ListItemLink
          to="/builders"
          selected={selectedMenu === 'builders'}
          icon={<SvgIcSqlBuilder />}
          primary="SQLビルダー"
          data-cy="drawer-sqlbuilder-list-link"
        />
      </List>
      <List subheader={<ListSubheader>組織内で共用する</ListSubheader>}>
        <Divider className={classes.divider} variant="middle" />
        <ListItemLink
          to="/connections"
          selected={selectedMenu === 'connections'}
          icon={<IconConnection />}
          primary="接続情報"
          data-cy="drawer-connection-list-link"
        />
        <ListItemLink
          to="/notification_dsts"
          selected={selectedMenu === 'notification_dsts'}
          icon={<SettingsInputComponent />}
          primary="通知先"
          data-cy="drawer-connection-list-link"
        />
        <ListItemLink
          to="/variables"
          selected={selectedMenu === 'variables'}
          icon={<TextFormat />}
          primary="共通変数"
          data-cy="drawer-variable-list-link"
        />
      </List>
      <List subheader={<ListSubheader>成果物を管理する</ListSubheader>}>
        <Divider className={classes.divider} variant="middle" />
        {user.organization.feature_dashboard && (
          <ListItemLink
            to="/reports"
            selected={selectedMenu === 'reports'}
            icon={<SvgIcDashboard />}
            primary="ダッシュボード"
            data-cy="drawer-report-list-link"
          />
        )}
        <ListItemLink
          to="/exports"
          selected={selectedMenu === 'exports'}
          icon={<IconExport />}
          primary="データエクスポート"
          data-cy="drawer-dataexport-list-link"
        />
      </List>
      {user.organization.feature_batch && (
        <List subheader={<ListSubheader>更新を管理する</ListSubheader>}>
          <Divider className={classes.divider} variant="middle" />
          <ListItemLink
            to="/schedules"
            selected={selectedMenu === 'schedules'}
            icon={<IconSchedule />}
            primary="スケジューリング"
            data-cy="drawer-schedule-list-link"
          />
          <ListItemLink
            to="/notifications"
            selected={selectedMenu === 'notifications'}
            icon={<SvgIcNotify />}
            primary="通知"
            data-cy="drawer-notification-list-link"
          />
        </List>
      )}
      {showAdminMenu && (
        <>
          <List subheader={<ListSubheader>nehanマネージャー用</ListSubheader>}>
            <Divider className={classes.divider} variant="middle" />
            <ListItemLink
              to="/admin/users"
              selected={selectedMenu === 'admin/users'}
              icon={<MdManageAccounts size="1.5em" />}
              primary="ユーザー管理"
              data-cy="drawer-admin-user-list-link"
            />
            <ListItemLink
              to="/admin/user_groups"
              selected={selectedMenu === 'admin/user_groups'}
              icon={<MdGroups size="1.5em" />}
              primary="ユーザーグループ管理"
              data-cy="drawer-admin-user-group-list-link"
            />
            {showResources && (
              <ListItemLink
                to="/admin/storage_usage"
                icon={<Storage />}
                selected={selectedMenu === 'admin/storage_usage'}
                primary="リソース状態"
                data-cy="drawer-admin-storage-status-list-link"
              />
            )}
            <ListItemLink
              to="/admin/payment"
              icon={<MdOutlineCreditCard size="1.5em" />}
              selected={selectedMenu === 'admin/payment'}
              primary="支払い設定"
              data-cy="drawer-admin-payment-list-link"
            />
            <ListItemLink
              to="/admin/projects"
              selected={selectedMenu === 'admin/projects'}
              primary="組織内全分析プロジェクト"
              data-cy="drawer-admin-project-list-link"
            />
            <ListItemLink
              to="/admin/datasources"
              selected={selectedMenu === 'admin/datasources'}
              primary="組織内全データソース"
              data-cy="drawer-admin-datasource-list-link"
            />
            <ListItemLink
              to="/admin/builders"
              selected={selectedMenu === 'admin/builders'}
              primary="組織内全SQLビルダー"
              data-cy="drawer-admin-sqlbuilder-list-link"
            />
            <ListItemLink
              to="/admin/connections"
              selected={selectedMenu === 'admin/connections'}
              primary="組織内全接続情報"
              data-cy="drawer-admin-connection-list-link"
            />
            <ListItemLink
              to="/admin/variables"
              selected={selectedMenu === 'admin/variables'}
              primary="組織内全共通変数"
              data-cy="drawer-admin-variable-list-link"
            />
            <ListItemLink
              to="/admin/reports"
              selected={selectedMenu === 'admin/reports'}
              primary="組織内全ダッシュボード"
              data-cy="drawer-admin-report-list-link"
            />
            {user.organization.feature_batch && (
              <ListItemLink
                to="/admin/schedules"
                selected={selectedMenu === 'admin/schedules'}
                primary="組織内全スケジューリング"
                data-cy="drawer-schedule-list-link"
              />
            )}
            <ListItemLink
              to="/admin/notifications"
              selected={selectedMenu === 'admin/notifications'}
              primary="組織内全通知"
              data-cy="drawer-admin-report-list-link"
            />
            <ListItemLink
              to="/admin/notification_dsts"
              selected={selectedMenu === 'admin/notification_dsts'}
              primary="組織内全通知先"
              data-cy="drawer-admin-report-list-link"
            />
          </List>
        </>
      )}
      {showNehanAdminMenu && (
        <>
          <Divider variant="middle" />
          <List subheader={<ListSubheader>nehan管理者用</ListSubheader>}>
            <ListItemLink
              to="/admin/organizations"
              selected={selectedMenu === 'admin/organizations'}
              primary="全ての組織"
              data-cy="drawer-admin-organization-list-link"
            />
            <ListItemLink
              to="/admin/backends"
              selected={selectedMenu === 'admin/backends'}
              primary="全てのバックエンド"
              data-cy="drawer-admin-backend-list-link"
            />
            <ListItemLink
              to="/admin/project_import"
              selected={selectedMenu === 'admin/project_import'}
              primary="プロジェクトのインポート"
            />
            <ListItemLink
              to="/admin/builder_import"
              selected={selectedMenu === 'admin/builder_import'}
              primary="ビルダーのインポート"
            />
            <ListItemLink
              to="/admin/project_export"
              selected={selectedMenu === 'admin/project_export'}
              primary="プロジェクトのエクスポート"
            />
            <ListItemLink
              to="/admin/builder_export"
              selected={selectedMenu === 'admin/builder_export'}
              primary="ビルダーのエクスポート"
            />
          </List>
        </>
      )}
    </Drawer>
  );
};

export default mainDrawer;
