import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItemType } from '../../../models/dependency';
import { Dialog } from './dialog';
import { typeToText } from '../listViewTableCell';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const styles = makeStyles(() => ({
  paper: {
    maxHeight: 830
  },
  content: {
    padding: 0,
    height: 'calc(830px - 58px)'
  },
  textField: {
    width: '100%'
  }
}));

export const ResourceNameDialog: React.FC<{
  type: ListItemType;
  name: string;
  title?: string;
  cancelButtonLabel?: string;
  open?: boolean;
  onChange: (name: string) => void;
  onClose?: () => void;
}> = ({
  type,
  onChange,
  onClose,
  name: initialName,
  title,
  cancelButtonLabel,
  open: externalOpen = false
}) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const firstDisplay = new URLSearchParams(location.search).has(
      'first_display'
    );
    // first_displayが付与されている場合は常時表示
    setOpen(firstDisplay || externalOpen);
  }, [externalOpen]);

  const close = React.useCallback(() => {
    setOpen(false);

    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('first_display');
    const newSearchString = searchParams.toString();
    history.replace({
      pathname: location.pathname,
      search: newSearchString
    });

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <ResourceNameDialogInternal
      initialName={initialName}
      open={open}
      onClose={close}
      title={title ?? `${typeToText[type]}に名前をつける`}
      cancelButtonLabel={cancelButtonLabel ?? '後で'}
      onChange={onChange}
    ></ResourceNameDialogInternal>
  );
};

const ResourceNameDialogInternal: React.FC<{
  initialName: string;
  title: string;
  cancelButtonLabel: string;
  open: boolean;
  onChange: (name: string) => void;
  onClose: () => void;
}> = ({ onChange, onClose, initialName, title, cancelButtonLabel, open }) => {
  const classes = styles();
  const [name, setName] = React.useState(initialName);

  React.useEffect(() => {
    if (open) {
      // モーダルを再オープンする際は、編集内容をリセット
      setName(initialName);
    }
  }, [initialName, open]);

  const onClickOK = React.useCallback(() => {
    onChange(name);
    onClose();
  }, [name]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      cancelButton={{ label: cancelButtonLabel }}
      OKButton={{
        onClick: onClickOK
      }}
    >
      <TextField
        className={classes.textField}
        label="名前を入力"
        onChange={(ev) => setName(ev.target.value)}
        value={name}
      />
    </Dialog>
  );
};
