import * as React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import MainLayout from 'components/layouts/main';
import ListView from 'components/ui/listView';
import { projectTheme } from '../../theme';
import {
  deleteSchedule,
  duplicateSchedule,
  getSchedules,
  updateScheduleName
} from 'libs/api/schedule';
import { useHistory, useParams } from 'react-router-dom';
import ShareResourceDialog, {
  BaseShareResourceProps
} from 'components/dialogs/shareResource';

const ScheduleListPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [state, setState] = React.useState<BaseShareResourceProps>({
    id: '',
    open: false,
    editable: false,
    title: '',
    type: 'schedule'
  });

  const onClose = () => {
    setState({ ...state, open: false });
  };

  const onDialogOpen = (
    id: string,
    shareEditable: boolean,
    projectName: string,
    isFolder: boolean
  ) => {
    setState({
      id,
      editable: shareEditable,
      open: true,
      title: projectName,
      type: isFolder ? 'folder' : 'schedule'
    });
  };

  const update = async (id: string, data: any) => {
    await updateScheduleName(id, data.name);
  };

  return (
    <MainLayout selectedMenu="schedules">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="schedules"
            folderId={id}
            showProject={true}
            load={getSchedules}
            supportFolder={true}
            move={(fid) => {
              history.push({
                pathname: '/schedules/new',
                search: new URLSearchParams({
                  folder: fid || '',
                  first_display: '1'
                }).toString()
              });
            }}
            delete={deleteSchedule}
            duplicate={duplicateSchedule}
            update={update}
            onOpenShareDialog={onDialogOpen}
            isOpenShareDialog={state.open}
          />
        </ThemeProvider>
        <ShareResourceDialog {...state} onClose={onClose} />
      </Grid>
    </MainLayout>
  );
};

export default ScheduleListPage;
